import axios from 'axios'
import {UserQueryResponse} from './_models'

const MAIN_API_ENDPOINT = process.env.REACT_APP_MAIN_API_ENDPOINT
const MAIN_USER_API_ENDPOINT = `${MAIN_API_ENDPOINT}/user`

const getUserList = async () => {
  const response = await axios.get<UserQueryResponse>(MAIN_USER_API_ENDPOINT)
  return response.data.data
}

export {getUserList}
