const QUERIES = {
  CONFIG: 'config',
  PROVINCE_LIST: 'province-list',
  COMMENT: 'comment',
  FLUCTUATION_REQUESTS: 'fluctuation-requests',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  USER_LIST: 'user-list',
}
const SPECIAL_KEYS = [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowTop',
  'ArrowBottom',
  'Delete',
  'Backspace',
  'Enter',
]
const MAIN_PATHNAME = 'fluctuation-requests'
const SUB_PATHNAME = {
  EDIT: 'edit',
  NEW: 'new',
  RESUBMIT: 'resubmit',
  SEND_APPROVE: 'send-approve',
  LIST: 'list',
  OVERVIEW: 'overview',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
}
const PHONE_REGEX = /^(0)(3[2-9]|5[2689]|7[0|6-9]|8[1-9]|9[0-9])[0-9]{7}$/
const NAME_REGEX = /^[A-Za-zÀ-ỹ\s]+$/
export {QUERIES, SPECIAL_KEYS, MAIN_PATHNAME, SUB_PATHNAME, PHONE_REGEX, NAME_REGEX}
