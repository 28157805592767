import {createContext, FC, useContext} from 'react'
import {WithChildren} from '../../../../_biha/helpers'
import {UserListSerialization} from './core/_models'
import {QueryResponseProvider, useQueryResponseData} from './core/QueryResponseProvider'

interface UserListContextProps {
  userList?: UserListSerialization[]
}

const UserListContext = createContext<UserListContextProps>({})

const UserListWrapper: FC<WithChildren> = ({children}) => {
  const userList = useQueryResponseData()
  return <UserListContext.Provider value={{userList}}>{children}</UserListContext.Provider>
}
const UserListProvider: FC<WithChildren> = ({children}) => {
  return (
    <QueryResponseProvider>
      <UserListWrapper>{children}</UserListWrapper>
    </QueryResponseProvider>
  )
}
const useUserList = () => useContext(UserListContext)

export {UserListProvider, useUserList}
