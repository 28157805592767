/* eslint-disable react-hooks/exhaustive-deps */
import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  hasBack?: boolean
  setBack: (_back: boolean) => void
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: PageLink[]
  setPageBreadcrumbs: (_breadcrumbs: PageLink[]) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setBack: (_back: boolean) => {},
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: PageLink[]) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<PageLink[]>([])
  const [hasBack, setBack] = useState<boolean>(false)
  const value: PageDataContextModel = {
    hasBack,
    setBack,
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

interface Props {
  description?: string
  breadcrumbs?: PageLink[]
  hasBack?: boolean
}

const PageTitle: FC<Props & WithChildren> = ({hasBack, children, description, breadcrumbs}) => {
  const {setBack, setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  useEffect(() => {
    if (hasBack) {
      setBack(hasBack)
    }
    return () => {
      setBack(false)
    }
  }, [children])

  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])
  return <></>
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDataProvider, PageDescription, PageTitle, usePageData}
