import qs, {ParsedQs} from 'qs'
import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import {
  LoadMoreQueryResponseContextProps,
  PaginationQueryResponseContextProps,
  QueryState,
} from './models'
import {String} from '../../../app/modules/apps/core/_models'
import {filter, isEmpty, join, map, omit} from 'lodash'

function createPaginationResponseContext<T>(initialState: PaginationQueryResponseContextProps<T>) {
  return createContext(initialState)
}
function createLoadMoreResponseContext<T>(initialState: LoadMoreQueryResponseContextProps<T>) {
  return createContext(initialState)
}
// Example: page=1&perPage=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(searchObject: ParsedQs): string {
  const paginationString = qs.stringify(searchObject, {
    arrayFormat: 'repeat',
    encode: false,
    filter: ['page', 'perPage'],
  })
  const sortString = qs.stringify(searchObject, {
    arrayFormat: 'repeat',
    encode: false,
    filter: ['availableSort', 'optionSort'],
  })
  // const search = isNotEmpty(state.search)
  //   ? qs.stringify(state, {filter: ['search'], skipNulls: true})
  //   : ''
  const filterObject = omit(searchObject, 'page', 'perPage', 'availableSort', 'optionSort')
  const filterString = filterObject
    ? join(
        map(
          filter(Object.entries(filterObject), ([_, value]) => !isEmpty(value)),
          ([key, value]) => `${key}=${value}`
        ),
        '&'
      )
    : ''

  return [paginationString, sortString, filterString].filter((f) => f).join('&')
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: T[] | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: T[] | undefined, selected: String[]): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: String,
  selected: String[],
  setSelected: Dispatch<SetStateAction<String[]>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<String[]>>,
  data?: (T & {_id?: String})[]
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item._id).map((item) => item._id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export {
  createPaginationResponseContext,
  createLoadMoreResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
}
