import Cookies from 'js-cookie'
import {AccessTokenType} from './_models'

const AUTH_COOKIES_KEY = 'authentication'
const getAuth = (): AccessTokenType | undefined => {
  const cookiesValue: string | undefined = Cookies.get(AUTH_COOKIES_KEY)
  if (!cookiesValue) {
    return
  }

  try {
    const auth: AccessTokenType = JSON.parse(cookiesValue) as AccessTokenType
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH COOKIES PARSE ERROR', error)
  }
}

const setAuth = (auth: AccessTokenType) => {
  try {
    const cookiesValue = JSON.stringify(auth)
    Cookies.set(AUTH_COOKIES_KEY, cookiesValue, {
      path: '/',
      domain: process.env.REACT_APP_BASE_DOMAIN,
    })
  } catch (error) {
    console.error('AUTH COOKIES SAVE ERROR', error)
  }
}

const removeAuth = () => {
  try {
    Cookies.remove(AUTH_COOKIES_KEY, {
      path: '/',
      domain: process.env.REACT_APP_BASE_DOMAIN,
    })
  } catch (error) {
    console.error('AUTH COOKIES REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      const {accessToken} = auth ?? {}
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_COOKIES_KEY}
