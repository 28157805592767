import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {WithChildren} from '../../../../_biha/helpers'
import {LayoutSplashScreen} from '../../../../_biha/layout/core'
import * as authHelper from './AuthHelpers'
import {AccessTokenType, B2BUserType} from './_models'
import {b2bGetMe} from './_requests'

interface AuthContextProps {
  auth: AccessTokenType | undefined
  saveAuth: (auth: AccessTokenType | undefined) => void
  currentUser: B2BUserType | undefined
  setCurrentUser: Dispatch<SetStateAction<B2BUserType | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AccessTokenType | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<B2BUserType | undefined>()
  const saveAuth = (auth: AccessTokenType | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S ACCESS TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (accessToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await b2bGetMe(accessToken)
          const {payload} = data.data.b2bGetMe ?? {}
          if (payload) {
            setCurrentUser(payload)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
