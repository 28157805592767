import {FC} from 'react'
import {useLang} from './BihaI18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import viMessages from './messages/vi.json'
import {WithChildren} from '../helpers'
import moment from 'moment'
import 'moment/locale/vi'

const allMessages = {
  vi: viMessages,
}

export const DEFAULT_FORMAT_DATE = 'YYYY-MM-DD'
export const DEFAULT_FORMAT_DATETIME = 'YYYY-MM-DD HH:mm'
export const DEFAULT_DISPLAY_DATE = 'DD/MM/YYYY'
export const DEFAULT_DISPLAY_DATETIME = 'DD/MM/YYYY, HH:mm'
export const DEFAULT_DISPLAY_FULL_DATETIME = 'HH:mm:ss, DD/MM/YYYY'
export const DEFAULT_DISPLAY_DATETIME_REVERSED = 'HH:mm, DD/MM/YYYY'

const LocaleProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]
  moment.locale(locale)
  moment.updateLocale('vi', {
    weekdaysMin: ['CN', 'Th2', 'Th3', 'Th4', 'Th5', 'Th6', 'Th7'],
    weekdaysShort: ['CN', 'Th2', 'Th3', 'Th4', 'Th5', 'Th6', 'Th7'],
  })

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {LocaleProvider}
