import {Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'

const AuthPage = () => {
  if (process.env.REACT_APP_AUTH_URL) {
    window.location.href = `${process.env.REACT_APP_AUTH_URL}?redirect=${window.location.href}`
    return null
  }

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
