import {floor, gte, isEmpty, isNumber, join, round} from 'lodash'
import {Number} from '../../app/modules/apps/core/_models'

const convertToVietnameseCurrencyLong = (number: number) => {
  if (!isNumber(number)) {
    return ''
  }

  const parts = []
  if (gte(number, 1e9)) {
    parts.push(`${floor(number / 1e9).toLocaleString()} tỷ`)
    number = number % 1e9
  }
  if (gte(number, 1e6)) {
    parts.push(`${floor(number / 1e6)} triệu`)
    number = number % 1e6
  }
  if (gte(number, 1e3)) {
    parts.push(`${floor(number / 1e3)} nghìn`)
    number = number % 1e3
  }
  if (gte(number, 1)) {
    parts.push(`${number} đồng`)
  }

  return isEmpty(parts) ? '0 đồng' : join(parts, ' ')
}

const convertToVietnameseCurrencyShort = (number: Number) => {
  if (!isNumber(number)) {
    return ''
  }

  return gte(number, 1e9)
    ? `${round(number / 1e9, 2).toLocaleString()} tỷ`
    : gte(number, 1e6)
    ? `${round(number / 1e6, 2)} triệu`
    : gte(number, 1e3)
    ? `${round(number / 1e3, 2)} nghìn`
    : gte(number, 1)
    ? `${round(number, 2)} đồng`
    : '0 đồng'
}

export {convertToVietnameseCurrencyLong, convertToVietnameseCurrencyShort}
