import axios, {AxiosResponse} from 'axios'
import {ConfigGetSerialization, ConfigsQueryResponse} from './_models'

const MAIN_API_ENDPOINT = process.env.REACT_APP_MAIN_API_ENDPOINT
const MAIN_CONFIG_API_ENDPOINT = `${MAIN_API_ENDPOINT}/config`

const getConfigurations = async (): Promise<ConfigGetSerialization | undefined> => {
  const response: AxiosResponse<ConfigsQueryResponse> = await axios.get(MAIN_CONFIG_API_ENDPOINT)
  return response.data.data
}

export {getConfigurations}
