import {FC, useContext} from 'react'
import {useQuery} from 'react-query'
import {
  QUERIES,
  WithChildren,
  createPaginationResponseContext,
  initialQueryResponse,
} from '../../../../../_biha/helpers'
import {GetListProvincesData} from './_models'
import {queryGetListProvinces} from './_requests'
import {AxiosResponse} from 'axios'

const QueryResponseContext =
  createPaginationResponseContext<AxiosResponse<GetListProvincesData>>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PROVINCE_LIST}`,
    () => {
      return queryGetListProvinces()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return undefined
  }
  return response || undefined
}
const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading}
