import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_biha/assets/ts/_utils'
import { WithChildren } from '../../_biha/helpers'
import { MasterLayout } from '../../_biha/layout/MasterLayout'

const PrivateRoutes = () => {
  const FluctuationRequestPage = lazy(() =>
    import('../modules/apps/fluctuation-request-management/FluctuationRequestPage').then(
      (module) => ({
        default: module.FluctuationRequestPage,
      })
    )
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/fluctuation-requests' />} />
        {/* Lazy Modules */}
        <Route
          path='fluctuation-requests/*'
          element={
            <SuspensedView>
              <FluctuationRequestPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

