import {FC, createContext, useContext} from 'react'
import {WithChildren} from '../../../../_biha/helpers'
import {QueryResponseProvider, useQueryResponseData} from './core/QueryResponseProvider'
import {GetListProvincesData} from './core/_models'
import {AxiosResponse} from 'axios'

interface AddressContextProps {
  province?: AxiosResponse<GetListProvincesData>
}
const AddressContext = createContext<AddressContextProps>({})

const AddressWrapper: FC<WithChildren> = ({children}) => {
  const province = useQueryResponseData()
  return <AddressContext.Provider value={{province}}>{children}</AddressContext.Provider>
}

const AddressProvider: FC<WithChildren> = ({children}) => {
  return (
    <QueryResponseProvider>
      <AddressWrapper>{children}</AddressWrapper>
    </QueryResponseProvider>
  )
}

const useAddress = () => useContext(AddressContext)

export {AddressProvider, useAddress}
